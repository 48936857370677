let mappingData = {
    "print_title":"标题",
    "base_info": "基础信息",
    "product_type": "产品类别",
    "item_code": "产品编码",
    "product_name": "产品名称",
    "customer_product_name": "客户定制产品名称",
    "product_description": "产品说明",
    "processing":"加工工艺",
    "version_number":"版本号",
    "physical_properties":"物理形态",
    "confidential":"保密文件",
    "custom_formula": "客户定制配方",
    "document_notes": "文件备注",
    "custom_field":"自定义字段",
    
    "general_specification": "产品信息",
    "ingredients": "成分",
    "common_other_name":"通用名/其他名称",
    "inci_name": "INCI名称",
    "botanical_name": "植物学名",                 
    "part_used": "使用部位",
    "percentage": "百分比",
    
    "custom_field_before_regular_data": "自定义字段",
    "country_of_origin": "原产地",
    "extraction_ratio": "提取率",
    "major_constituents_in_native_herbs": "植物主要成分",
    "important_constitue_in_plant": "植物主要成分",
    "appearance": "外观",
    "solubility":"溶解度",
    "homogeneity":"均匀度",
    "extraction_process":"提取过程",
    "processing_aid":"加工助剂",
    "solvent_for_extraction":"提取溶剂",
    "solvent":"溶剂",
    "carrier":"辅料",
    "other_ingredients": "其他成分",
    "pesticides":"农药残留",
    "certification":"证书",
    "preservative": "防腐剂",
    "product_grade":"产品级别",
    

    "analysis": "分析项目",
    "standard1" : "标准1",
    "standard2" : "标准2",
    "standard3" : "标准3",
    "mesh_size" : "过筛尺寸",
    "loss_on_drying" : "干燥失重",
    "bulk_density" : "堆密度",
    "identification": "HPTLC鉴定",
    "bioactives_level" : "活性成分控制指标",
    "moisture": "水分含量",
    "specifica_gravity" : "比重",
    "refractive_index" : "折光率",
    "optical_rotation" : "旋光度",
    "acid_value" : "酸值",
    "peroxide_value" : "过氧化值",
    "saponification_value" : "皂化值",
    "unsaponifiable_master" : "不皂化物含量",
    "moisture_volatiles" : "水分和挥发物含量",
    "ph" : "pH",
    "total_heavy_metals" : "重金属",
    "arsenic" : "砷",
    "cadmium" : "镉",
    "lead" : "铅",
    "mercury" : "汞",
    "color" : "颜色",
    "odor" : "气味",
    "taste" : "味道",

    
    "microbiological_tests":"微生物测试",
    "total_plate_count" : "细菌总数",
    "yeast_and_mold" : "酵母和霉菌",
    "ecoli": "大肠杆菌",
    "salmonella" : "沙门氏菌",
    "staph_aureus" : "金色葡萄球菌",
    "total_coliforms" : "大肠菌群总数",
    
    "fatty_acid": "脂肪酸",
    "caprylic" : "C8:0 辛酸",
    "capric" : "C10:0 癸酸",
    "lauric" : "C12:0 月桂酸",
    "myristic" : "C14:0 豆蔻酸",
    "myristoleic" : "C14:1 豆蔻油酸",
    "palmitica" : "C16:0 棕榈酸",
    "palmitoleic" : "C16:1 棕榈油酸",
    "stearic" : "C18:0 硬脂酸",
    "oleic" : "C18:1 油酸",
    "linoleicacid" : "C18:2 亚油酸",
    "a_linolenic" : "C18:3 α-亚麻酸",
    "y_linoleic" : "C18:3 γ-亚麻酸",
    "punicicacid" : "C18:3 石榴酸/Punicic acid",
    "arachidic" : "C20:0 花生酸/AA",
    "arachidonic" : "C20:4 花生四烯酸",
    "eicosapentaenoic" : "C20:5 二十碳五烯酸/EPA",
    "docosahexaenoic" : "C22:6 二十二碳六烯酸/DHA",
    "composition": "成分组成",
    "others" : "其它",

    
    "other_notes":"其他信息",
    "shelflife_number":   "保质期(*个月)",
    "shelf_life" : "保质期备注",
    "storage" : "储存条件",
    "package_info" : "包装"

}    

// 通过 value 获取 key
export function getKeyByValue(value) {
    for (let key in mappingData) {
        if (mappingData[key] === value) {
            return key;
        }
    }
    return null; // 未找到返回 null
}

// 通过 key 获取 value
export function getValueByKey(key) {
    return mappingData[key] !== undefined ? mappingData[key] : null; // 如果存在返回 value，否则返回 null
}